import React, { Suspense, lazy, memo, useMemo } from "react";
import PropagateLoader from "react-spinners/PropagateLoader";
import useAuth from "utils/hooks/useAuth";
import { Loading } from "components/shared";
import { FilterProvider } from "context/FilterProvider";

const Fallback = () => (
    <div className="flex flex-auto flex-col h-[100vh]">
        <Loading customLoader={ <PropagateLoader /> } loading={ true } />
    </div>
);

const Layout = () => {

    const { authenticated } = useAuth();

    const AppLayout = useMemo(() => {
        if (authenticated) {
            return lazy(() => import("./Layout"));
        }
        return lazy(() => import("./AuthLayout"));
    }, [authenticated]);

    return (
        <Suspense fallback={ <Fallback /> }>
            { authenticated ? <FilterProvider><AppLayout /></FilterProvider> :  <AppLayout /> }
        </Suspense>
    );
};

export default memo(Layout);
