/* eslint-disable react-hooks/rules-of-hooks */
import { useMemo } from "react";
import { useSelector } from "react-redux";
import useSWR from "swr";
import useSWRInfinite from "swr/infinite";
import { get, post } from "./HttpHelpers";

export const usePost = (ListUrl, queryParams) => {
    const fetcher = () => post(ListUrl, { ...queryParams });
    const swr = useSWR(ListUrl, fetcher);
    return {
        ...swr,
        isLoading: !swr.data && !swr.error
    };
};

export const useGetFilter = (ListUrl, queryParams, fetch=true) => {
    const fetcher = async () => await get(`${ListUrl}?filter=${encodeURIComponent(JSON.stringify(queryParams))}`);
    const swr = useSWR(fetch ? ListUrl+encodeURIComponent(JSON.stringify(queryParams)) : null, fetcher,  {
        revalidateOnFocus: false,
        revalidateIfStale: false
        // revalidateOnReconnect: false, // personally, I didn't need this one
    });
    
    const isLoading = useMemo(() => !swr.data && !swr.error, [swr]);

    return {
        ...swr,
        isLoading
    };
};

export const useGet = (ListUrl, queryParams, fetch=true) => {
    const fetcher = async () => await get(`${ListUrl}?${encodeURIComponent(queryParams)}`);
    const swr = useSWR(fetch ? ListUrl+encodeURIComponent(queryParams) : null, fetcher, {
        revalidateOnFocus: false,
        revalidateIfStale: false });
    
    const isLoading = useMemo(() => !swr.data && !swr.error, [swr]);

    return {
        ...swr,
        isLoading
    };
};

export const useCompanyData = () => {
    const user = useSelector((state) => state.auth.user);
    const isAdmin = useMemo(() => user?.authority?.some(el => el === "admin"), [user]);
    return isAdmin ? useGetFilter("/company/back-office/", 
        { filter: { active: 1 }, pageSize: 0, pageNumber:0 }, isAdmin) : 
         { data: { result: [{ id:user?.user?.company?.id, name:user?.user?.company.name }] } };
};

export const useStoreData = (selectedCompany) => {
    return useGetFilter("/store/back-office/", 
        { filter: { active: 1,  company_id: selectedCompany?.value }, 
        pageSize: 0, pageNumber:0 }, selectedCompany);
};


export const useInfinite = (ListUrl, queryParams) => {
    const fetcher = (url) => get(url);
    return useSWRInfinite(
        (index) =>
          `${ListUrl}?filter=${encodeURIComponent(JSON.stringify({ ...queryParams,pageNumber: index + 1 }))}`,
        fetcher
      );
};
